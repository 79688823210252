.App {
   text-align: center;
   min-height: 100%;
   display: grid;
   grid-template-rows: auto 1fr auto;
   grid-template-columns: 100%;
}

html,
body {
   margin: 0;
   font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
      "Droid Sans", "Helvetica Neue", sans-serif;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   width: 100%;
   height: 100%;
   background-color: #282c34;
}

#App {
   font-family: "Roboto";

   /* Give app full page to work with */
   height: 100vh;
}

/* Individual item */
.bm-item {
   display: inline-block;

   /* Our sidebar item styling */
   text-decoration: none;
   margin-bottom: 10px;
   color: #d1d1d1;
   transition: color 0.2s;
}

/* Change color on hover */
.bm-item:hover {
   color: white;
}

/* The rest copied directly from react-burger-menu docs */

/* Position and sizing of burger button */

@media (min-width: 801px) {
   .bm-burger-button {
      position: fixed;
      width: 36px;
      height: 30px;
      left: 36px;
      top: 36px;
   }
}

@media (max-width: 800px) {
   .bm-burger-button {
      position: fixed;
      width: 23px;
      height: 18px;
      left: 18px;
      top: 18px;
   }
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
   background: #2556c0;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
   height: 24px;
   width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
   background: #bdc3c7;
}

/* General sidebar styles */
.bm-menu {
   background: #2556c0;
   padding: 2.5em 1.5em 0;
   font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
   fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
   color: #b8b7ad;
}

/* Styling of overlay */
.bm-overlay {
   background: rgba(0, 0, 0, 0.3);
}
