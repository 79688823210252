.calc-button {
   font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
      "Helvetica Neue", sans-serif;
   font-size: x-large;
   margin: 2px;
   width: 70px;
   height: 70px;
   font-weight: 700;
   border-radius: 6px;
   border-color: black;
   border-width: 3px;
}

.calc-row {
   display: flex;
   flex: 1 1 auto;
   flex-direction: row;
   justify-content: right;
}

.calc {
   min-height: 100vh;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
}

.calc-value-display {
   background: white;
   height: 60px;
   font-size: 44px;
   word-wrap: break-word;
   overflow: hidden;
   border-color: #ffffff;
   border-width: 5px;
}

.calc-background {
   border-style: solid;
   border-width: 4px;
   background: #122030;
}
